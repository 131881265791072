import { graphql } from 'gatsby'
import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { css } from 'styled-components'
import { breakpoints, fontSizes, mediaQueries, space } from '../utils/tokens'
import { ButtonSelector } from './ButtonSelector'
import Container from './Container'

export const HeroTextOnly = ({ hero }) => {
  if(!hero) {
    return null
  }
  
  return (
    <section
      css={css({
        padding: `calc(${space[6]}px + ${space[5]}px)  ${space[4]}px ${space[5]}px`,
        [mediaQueries.md]: {
          padding: `calc(${space[6]}px + ${space[5]}px)  ${space[4]}px ${space[5]}px`,
        },
      })}
    >
      <Container
        css={css({
          display: `flex`,
          flexDirection: `column`,
          [mediaQueries.md]: {
            alignItems: `center`,
          },
        })}
      >
        <h1
          css={css({
            [mediaQueries.md]: {
              textAlign: `center`,
              maxWidth: `900px`,
              textTransform: `uppercase`,
            },
          })}
        >
          <span
            css={css({
              fontSize: fontSizes[2],
              display: `block`,
              backgroundClip: `text`,
              WebkitBackgroundClip: `text`,
              WebkitTextFillColor: `transparent`,
              backgroundImage: `linear-gradient(90deg,#ED5C68, #002A66)`,
              [mediaQueries.lg]: {
                fontSize: fontSizes[3],
              },
            })}
          >
            {hero.subtitle}
          </span>
          <span>{' '}</span>
          <span
            css={css({
              fontSize: fontSizes[5],
              display: `block`,
              backgroundClip: `text`,
              WebkitBackgroundClip: `text`,
              WebkitTextFillColor: `transparent`,
              backgroundImage: `linear-gradient(90deg,#F5A623,#ED5C68,#663399, #002A66)`,
              [mediaQueries.lg]: {
                fontSize: fontSizes[6],
              },
            })}
          >
            {hero.title}
          </span>
        </h1>
        <div
          css={css({
            maxWidth: breakpoints.sm,
            margin: `0 auto`,
            fontSize: fontSizes[3],
            marginTop: `${space[4]}px`,
            [mediaQueries.md]: {
              textAlign: `center`,
            },
          })}
        >
          {hero.body?.raw && renderRichText(hero.body)}
        </div>
        <div
          css={css({
            marginTop: `${space[4]}px`,
          })}
        >
          {hero?.buttons?.length > 0 &&
            hero.buttons.map(button => {
              return <ButtonSelector key={button.id} button={button} />
            })}
        </div>
      </Container>
    </section>
  )
}

export const heroTextOnlyFragment = graphql`
  fragment HeroTextOnly on ContentfulHeroTextOnly {
    title
    subtitle
    body {
      raw
    }
    buttons {
      ... on Node {
        __typename
        ... on ContentfulButtonBasic {
          id
          ...ButtonBasic
        }
        ... on ContentfulButtonTypeform {
          id
          ...ButtonTypeform
        }
      }
    }
  }
`
