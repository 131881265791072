import React from 'react'
import posed from 'react-pose'
import styled, { css } from 'styled-components'

import theme from '../../styles/theme'

const Root = posed.div({
  open: {
    staggerChildren: 100
  },
  closed: {
    staggerChildren: 100,
    staggerDirection: -1
  }
});

const Wall = styled(posed.div({
  open: {
    x: 0,
    transition: {
      ease: [
        0.77, 0, 0.175, 1
      ],
      duration: 600
    }
  },
  closed: {
    x: `100%`,
    transition: {
      ease: [
        0.77, 0, 0.175, 1
      ],
      duration: 1000
    }
  }
}))`
  width: 100vw;
  height: 100%;
  background-color: ${props => props.color || theme.colors.base};
  position: absolute;
  z-index: 1000;
`

const BackgroundSlide = (props) => {
  return (
    <Root css={css`
      position: relative;
      z-index: 0;
      height: 100%;
    `}>
      <Wall color={theme.colors.primaryBrand} />
      <Wall color={theme.colors.lightBase} />
      <Wall color={theme.colors.base} />
    </Root>
  )
}

export default BackgroundSlide