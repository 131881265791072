import React from "react";
import styled from "styled-components";
import posed from "react-pose";
import { space } from "../../utils/tokens";

const scale = {
  hoverable: true,
  init: {
    scale: 1
  },
  hover: {
    scale: 1.1
  }
};

const BurgerContainer = styled.div `
  display: block;
  position: fixed;
  left: ${space[3]}px;
  bottom: 32px;
  z-index: 999;
  padding-bottom: env(safe-area-inset-bottom);
`;

const BurgerBox = styled(posed.div(scale))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 48px;
  height: 48px;
  background: ${props => props.theme.colors.primaryBrand};
  border-radius: 50%;
  box-shadow: 0 0 0 3px white;
`;

const transition = {
  duration: 450
};

const BarStyle = styled.div`
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 2px 0;
  transform-origin: top left;
`;

const Bar = (props) => <BarStyle {...props} ref={props.hostRef} />;

const Bar1 = posed(Bar)({
  closed: {
    rotate: 0,
    x: "0",
    y: "0",
    transition
  },
  open: {
    rotate: "45deg",
    x: "4px",
    y: "-1px",
    transition
  }
});

const Bar2 = posed(Bar)({
  closed: {
    opacity: 1,
    transition
  },
  open: {
    tranform: "none",
    opacity: 0
  }
});

const Bar3 = posed(Bar)({
  closed: {
    rotate: 0,
    x: "0",
    y: "0",
    transition
  },
  open: {
    rotate: "-45deg",
    x: "2px",
    y: "3px",
    transition
  }
});


export const BurgerMenu = ({
  isOpen,
  ...props
}) => {
  return (
    <BurgerContainer {...props}>
      <BurgerBox>
        <Bar1 key="b1" pose={isOpen
          ? "open"
          : "closed"} />
        <Bar2 key="b2" pose={isOpen
          ? "open"
          : "closed"} />
        <Bar3 key="b3" pose={isOpen
          ? "open"
          : "closed"} />
      </BurgerBox>
    </BurgerContainer>
  );
};
