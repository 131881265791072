import { Link } from 'gatsby'
import React from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { css } from 'styled-components'

import PrintAWorldLogo from '../assets/logo/printaworld-logo-black.svg'
import { breakpoints, colors, fontSizes, mediaQueries, space } from '../utils/tokens'
import Button from './Button'
import DesktopMenu from './DesktopMenu/DesktopMenu'

const activeStyleColor = {
  backgroundClip: `text`,
  WebkitBackgroundClip: `text`,
  WebkitTextFillColor: `transparent`,
  backgroundImage: `linear-gradient(90deg,#ED5C68, #002A66)`,
}

const linkStyle = {
  textDecoration: `none`,
  fontWeight: `bold`,
  textTransform: `uppercase`,
  fontSize: fontSizes[1],
}

export const Header = ({ theme, location }) => {
  const isGetAQuotePage = location && location.pathname === `/get-a-quote/`
  const isDarkTheme = theme === `dark`

  return (
    <header
      css={css({
        width: `100%`,
        zIndex: 2,
        position: `absolute`,
        [mediaQueries.lg]: {
          display: `block`,
          color: isDarkTheme ? `white` : colors.base,
        },
      })}
    >
      <DesktopMenu />
      <nav
        css={css({
          width: `100%`,
          maxWidth: breakpoints.xxl,
          margin: `0 auto`,
          padding: `${space[4]}px`,
          [mediaQueries.lg]: {
            display: `flex`,
            padding: ` ${space[3]}px`,
          },
          [mediaQueries.xl]: {
            padding: `${space[4]}px`,
          },
        })}
      >
        <div
          css={css({
            width: `100%`,
            display: `flex`,
            [mediaQueries.lg]: {
              flex: 1,
              justifyContent: `center`,
            },
          })}
        >
          <div css={css({ flex: 1 })}>
            <Link to="/" exact="true">
              <PrintAWorldLogo
                css={css({
                  fill: theme === `dark` ? `white` : colors.base,
                  height: `32px`,
                })}
              />
            </Link>
          </div>
          <ul
            css={css({
              listStyle: `none`,
              margin: 0,
              padding: 0,
              display: `none`,
              margin: 0,
              [mediaQueries.lg]: {
                display: `flex`,
                padding: `0 ${space[3]}px`,
                justifyContent: `space-between`,
                alignItems: `center`,
              },
              li: {
                ':first-of-type': {
                  marginLeft: 0,
                },
                marginLeft: `${space[3]}px`,
                [mediaQueries.xl]: {
                  marginLeft: `${space[4]}px`,
                },
              },
            })}
          >
            <li>
              <Link
                to="/services/"
                exact="true"
                activeStyle={activeStyleColor}
                css={css(linkStyle)}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="/the-lab/"
                exact="true"
                activeStyle={activeStyleColor}
                css={css(linkStyle)}
              >
                The Lab
              </Link>
            </li>
            <li>
              <Link
                to="/portfolio/"
                exact="true"
                activeStyle={activeStyleColor}
                css={css(linkStyle)}
              >
                Portfolio
              </Link>
            </li>
            <li>
              <Link
                to="/gallery/"
                exact="true"
                activeStyle={activeStyleColor}
                css={css(linkStyle)}
              >
                Gallery
              </Link>
            </li>
            <li>
              <Link
                to="/about/"
                exact="true"
                activeStyle={activeStyleColor}
                css={css(linkStyle)}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/blog/"
                exact="true"
                activeStyle={activeStyleColor}
                css={css(linkStyle)}
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                to="/contact/"
                activeStyle={activeStyleColor}
                css={css(linkStyle)}
              >
                Contact us
              </Link>
            </li>
          </ul>
          <div
            css={css({
              display: `none`,
              [mediaQueries.md]: {
                display: `flex`,
                justifyContent: `flex-end`,
                flex: 1,
              },
            })}
          >
            <Link to="/get-a-quote/" css={css({ textDecoration: `none` })}>
              <Button
                text="GET A QUOTE"
                css={css`
                  font-size: 16px;
                  &&& {
                    background-color: ${isGetAQuotePage
                      ? colors.white
                      : `unset`};
                    background-image: ${isGetAQuotePage ? `none` : null};
                    color: ${isGetAQuotePage ? colors.primaryBrand : null};
                  }
                `}
                icon={
                  <FaArrowRight
                    css={css`
                      margin-left: 0.5rem;
                    `}
                  />
                }
              />
            </Link>
          </div>
        </div>
      </nav>
    </header>
  )
}