import React from 'react'
import { css } from 'styled-components'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel'
import { GatsbyImage } from 'gatsby-plugin-image'

import {
  mediaQueries,
  colors,
  space,
  boxShadows,
  fontSizes,
  breakpoints,
} from '../utils/tokens'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Container from './Container'
import { ButtonSelector } from './ButtonSelector'
import { UnorderedList } from './Article/UnorderedList'

export const HeroWithBlock = ({ hero, buttonGroup, height }) => {
  if(!hero) {
    return null
  }

  console.log(hero)

  const { title, subtitle, body, block, buttons, isFullScreen } = hero

  return (
    <section
      css={css({
        position: `relative`,
        overflow: `hidden`,
      })}
    >
      <Container
        css={css({
          maxWidth: `unset`,
          [mediaQueries.xxxxl]: {
            maxWidth: breakpoints.xxxl,
          },
        })}
      >
        <div
          css={css({
            display: `flex`,
            flexDirection: `column-reverse`,
            [mediaQueries.md]: {
              display: isFullScreen ? `flex` : `grid`,
              justifyContent: `center`,
              gridTemplateColumns: `1fr 1fr`,
            },
            [mediaQueries.xxxl]: {
              gridTemplateColumns: `1fr 1fr`,
            },
          })}
        >
          <div
            css={css({
              zIndex: 1,

              display: `flex`,
              flexDirection: `column`,
              padding: `80px ${space[4]}px ${space[4]}px`,
              justifyContent: `center`,
              [mediaQueries.md]: {
                padding: `${space[6]}px ${space[4]}px`,
                position: isFullScreen ? `absolute` : `relative`,
              },
              [mediaQueries.lg]: {
                justifyContent: `flex-start`,
                padding: `calc(80px + ${space[6]}px) ${space[5]}px 80px`,
              },
              [mediaQueries.xl]: {
                padding: `180px calc(80px + ${space[5]}px) 80px`,
                maxWidth: `${breakpoints.md}`,
                marginLeft: `auto`,
              },
            })}
          >
            <h1
              css={css({
                marginBottom: `${space[3]}px`,
              })}
            >
              {subtitle && (
                <span
                  css={{
                    display: `block`,
                    fontSize: fontSizes[2],
                    color: colors.primaryBrand,
                    [mediaQueries.md]: {
                      fontSize: fontSizes[3],
                    },
                  }}
                >
                  {subtitle}{' '}
                </span>
              )}
              <span
                css={css({
                  fontWeight: `bold`,
                  display: `block`,
                  fontSize: fontSizes[4],
                  [mediaQueries.xl]: {
                    fontSize: fontSizes[5],
                  },
                })}
              >
                {title}
              </span>
            </h1>
            {body && (
              <div
                css={css({
                  b: { color: colors.primaryBrand, fontWeight: `600` },
                })}
              >
                {renderRichText(body, options)}
              </div>
            )}
            <div
              css={css({
                marginTop: `${space[4]}px`,
                display: `flex`,
              })}
            >
              {buttons?.length > 0 &&
                buttons.map((button, id) => {
                  return (
                    <div
                      key={button.id}
                      css={css({
                        display: `flex`,
                        width: `100%`,
                        [':not(:first-of-type)']: {
                          marginLeft: `${space[3]}px`,
                        },
                        [mediaQueries.md]: { width: `auto` },
                      })}
                    >
                      <ButtonSelector
                        button={button}
                        css={css({
                          width: `100%`,
                          [mediaQueries.md]: { width: `auto` },
                        })}
                      />
                    </div>
                  )
                })}
            </div>
          </div>
          {block && (
            <HeroBlockSelector block={block} isFullScreen={isFullScreen} />
          )}
        </div>
      </Container>
    </section>
  )
}

const HeroBlockSelector = ({ block, isFullScreen }) => {
  switch (block.__typename) {
    case 'ContentfulBlockGallery': {
      if (isFullScreen) {
        return <HeroBlockGalleryFullScreen block={block} />
      } else {
        return <HeroBlockGalleryCarousel blockGallery={block} />
      }
    }
    case 'ContentfulBlockImage': {
      return <HeroBlockImage blockImage={block} />
    }
    default:
      return null
  }
}

const HeroBlockImage = ({ blockImage }) => {
  if (!blockImage) {
    return null
  }

  const { image, altText } = blockImage

  return (
    <div
      css={css({
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `center`,
        [mediaQueries.lg]: {
          marginTop: `112px`,
        },
      })}
    >
      {image && (
        <GatsbyImage
          image={image?.gatsbyImageData}
          alt={altText}
          loading="eager"
        />
      )}
    </div>
  )
}

const HeroBlockGalleryFullScreen = ({ block, height }) => {
  if (!block?.images?.length > 0) {
    return null
  }
  return (
    <div
      css={css({
        position: `relative`,
        top: 0,
        left: 0,
        width: `100%`,
      })}
    >
      <div
        css={css({
          position: `relative`,
        })}
      >
        <CarouselProvider
          totalSlides={block.images.length}
          naturalSlideHeight={1080}
          naturalSlideWidth={1920}
          infinite
          css={css({
            [mediaQueries.md]: {
              height: `auto`,
            },
          })}
        >
          <Slider>
            {block.images.map((blockImage, id) => {
              const isFirst = id === 0
              return (
                <Slide key={blockImage.id}>
                  {blockImage.image?.gatsbyImageData && (
                    <div>
                      <GatsbyImage
                        image={blockImage.image.fullScreen}
                        alt={blockImage.altText}
                        loading={isFirst ? 'eager' : 'lazy'}
                        css={css({
                          position: `absolute !important`,
                          width: `100%`,
                          height: `100%`,
                        })}
                      />
                    </div>
                  )}
                </Slide>
              )
            })}
          </Slider>
          {block.images.length > 1 && (
            <div
              css={css({
                position: `absolute`,
                bottom: `${space[3]}px`,
                right: `${space[3]}px`,
                display: `grid`,
                gridTemplateColumns: `1fr 1fr`,
                gridGap: `${space[3]}px`,
                [mediaQueries.lg]: {
                  bottom: `${space[5]}px`,
                  right: `${space[5]}px`,
                },
              })}
            >
              <ButtonBack
                css={css({
                  ...buttonStyle,
                })}
              >
                <IoIosArrowBack />
              </ButtonBack>
              <ButtonNext
                css={css({
                  ...buttonStyle,
                })}
              >
                <IoIosArrowForward />
              </ButtonNext>
            </div>
          )}
        </CarouselProvider>
      </div>
    </div>
  )
}

const HeroBlockGalleryCarousel = ({ blockGallery, height }) => {
  if(!blockGallery?.images?.length > 0 ) {
    return null
  }


  return (
    <div
      css={css({
        position: `relative`,
      })}
    >
      <CarouselProvider
        totalSlides={blockGallery.images.length}
        naturalSlideHeight={1800}
        naturalSlideWidth={1440}
        infinite
        css={css({
          [mediaQueries.md]: {
            height: `auto`,
          },
          [mediaQueries.xxl]: {
            overflow: `hidden`,
            height: height || `100vh`,
          },
          [mediaQueries.xxxxl]: {
            height: `auto`,
          }
        })}
      >
        <Slider>
          {blockGallery.images.map((blockImage, id) => {
            const isFirst = id === 0
            return (
              <Slide key={blockImage.id}>
                {blockImage.image?.gatsbyImageData && (
                  <div>
                    <GatsbyImage
                      image={blockImage.image.gatsbyImageData}
                      alt={blockImage.altText}
                      loading={isFirst ? 'eager' : 'lazy'}
                      css={css({
                        position: `absolute !important`,
                        width: `100%`,
                        height: `100%`,
                      })}
                    />
                  </div>
                )}
                <div
                  css={css({
                    position: `relative`,
                    width: `100%`,
                    height: `100%`,
                    top: 0,
                    left: 0,
                    '::before': {
                      content: `""`,
                      position: `absolute`,
                      bottom: 0,
                      width: `100%`,
                      height: `${space[7]}px`,
                      backgroundImage: `linear-gradient(-180deg,rgba(0,0,0,0) 2%,rgb(255, 255, 255) 100%) !important;`,
                    },
                    [mediaQueries.md]: {
                      '::before': {
                        width: 0,
                      },
                    },
                    [mediaQueries.xxl]: {
                      height: height || `100vh`,
                    },
                  })}
                >
                  <div
                    css={css({
                      position: `absolute`,
                      bottom: `${space[2]}px`,
                      left: `${space[4]}px`,
                      maxWidth: `50%`,
                      [mediaQueries.lg]: {
                        bottom: `${space[5]}px`,
                        left: `${space[5]}px`,
                      },
                    })}
                  >
                    <span
                      css={css({
                        display: `block`,
                        textAlign: `center`,
                        fontSize: fontSizes[2],
                        color: colors.base,
                        fontWeight: `500`,
                        backgroundColor: colors.white,
                        boxShadow: boxShadows.card,
                        padding: `${space[2]}px ${space[3]}px`,
                        borderRadius: `4px`,
                      })}
                    >
                      {blockImage.caption}
                    </span>
                  </div>
                </div>
              </Slide>
            )
          })}
        </Slider>
        <DotGroup
          css={css({
            position: `absolute`,
            top: `${space[3]}px`,
            left: `${space[4]}px`,
            display: `none`,
            [mediaQueries.lg]: {
              display: `block`,
              top: `calc(${space[5]}px + ${space[3]}px)`,
              left: `${space[4]}px`,
            },
            '.carousel__dot': {
              height: `12px`,
              width: `12px`,
              padding: 0,
              backgroundColor: `unset`,
              borderRadius: `100%`,
              border: `1px solid ${colors.base}`,
              marginRight: `${space[2]}px`,
              ':disabled': {
                backgroundColor: colors.base,
              },
            },
          })}
        />
        <div
          css={css({
            position: `absolute`,
            zIndex: 5,
            bottom: `${space[3]}px`,
            right: `${space[3]}px`,
            display: `grid`,
            gridTemplateColumns: `1fr 1fr`,
            gridGap: `${space[3]}px`,
            [mediaQueries.lg]: {
              bottom: `${space[5]}px`,
              right: `${space[5]}px`,
            },
          })}
        >
          <ButtonBack
            css={css({
              ...buttonStyle,
            })}
          >
            <IoIosArrowBack />
          </ButtonBack>
          <ButtonNext
            css={css({
              ...buttonStyle,
            })}
          >
            <IoIosArrowForward />
          </ButtonNext>
        </div>
      </CarouselProvider>
    </div>
  )
}

const buttonStyle = {
  borderRadius: `100%`,
  border: `none`,
  width: `48px`,
  height: `48px`,
  fontSize: fontSizes[3],
  backgroundColor: colors.white,
  boxShadow: `${boxShadows.card}`,
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
}

const options = {
  renderNode: {
    [BLOCKS.UL_LIST]: (node, children) => (
      <UnorderedList>{children}</UnorderedList>
    ),
  },
}
