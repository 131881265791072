import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'

import GlobalStyle from '../styles/global'

import config from '../utils/siteConfig'
import theme from '../styles/theme'
import Footer from './Footer'
import { Header } from './Header'

export const MainLayout = ({ children, headerTheme }) => {
  return (
    <div className="siteRoot">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="preconnect dns-prefetch"
          href="https://marketingplatform.google.com"
        />
        <link rel="preconnect dns-prefetch" href="https://www.google.com" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <div>
          <GlobalStyle />
          <Header theme={headerTheme} />
          <div className="siteContent">{children}</div>
        </div>
      </ThemeProvider>
      <ThemeProvider theme={theme}>
        <Footer />
      </ThemeProvider>
    </div>
  )
}
