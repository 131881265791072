import React from 'react'
import { css } from 'styled-components'

import { mediaQueries, space, fontSizes, breakpoints, colors } from '../utils/tokens'

import Container from './Container'
import GatsbyImage from 'gatsby-image'

import DiagonalShape from '../assets/shapes/diagon.svg'

export const HeroDiagonal = ({ hero }) => {
  return (
    <section
      css={css({
        [mediaQueries.lg]: {},
      })}
    >
      <Container
        css={css({
          maxWidth: breakpoints.xxl,
          display: `flex`,
          flexDirection: `column-reverse`,
          [mediaQueries.lg]: {
            flexDirection: `row`,
          },
        })}
      >
        <div
          css={css({
            position: `relative`,
            [mediaQueries.lg]: {
              width: `50%`,
              paddingTop: `${space[4]}px`,
              zIndex: 1,
            },
            [mediaQueries.xxl]: {
              paddingTop: `${space[5]}px`,
            },
          })}
        >
          <DiagonalShape
            css={css({
              display: `none`,
              [mediaQueries.lg]: {
                display: `block`,
                height: `100%`,
                position: `absolute`,
                top: 0,
                left: 0,
                fill: colors.lightBase,
              },
            })}
          />
          <div
            css={css({
              maxWidth: breakpoints.sm,
              padding: `${space[4]}px`,
              marginLeft: `auto`,
              position: `relative`,
            })}
          >
            <h1
              css={css({
                textTransform: `uppercase`,
                letterSpacing: `1.5px`,
                marginBottom: `${space[2]}px`,
                fontSize: fontSizes[4],
                fontWeight: `bold`,
                [mediaQueries.lg]: {
                  fontSize: fontSizes[5],
                  color: colors.white,
                  marginBottom: `${space[3]}px`,
                },
              })}
            >
              {hero.title}
            </h1>
            {hero.tagline && (
              <div
                dangerouslySetInnerHTML={{
                  __html: hero.tagline.childMarkdownRemark.html,
                }}
                css={css({
                  fontSize: fontSizes[3],
                  color: colors.base,
                  [mediaQueries.lg]: {
                    color: colors.white,
                    lineHeight: `1.2`,
                  },
                })}
              />
            )}
          </div>
        </div>
        {hero.backgroundPhoto && (
          <div
            css={css({
              height: `100%`,
              [mediaQueries.lg]: {
                position: `relative`,
                width: `50%`,
                overflow: `hidden`,
              },
            })}
          >
            <GatsbyImage
              fluid={hero.backgroundPhoto.fluid}
              alt={hero.backgroundPhoto.title}
              css={css({
                height: `100%`,
                width: `100%`,
                '::before': {
                  content: `""`,
                  position: `absolute`,
                  bottom: 0,
                  width: `100%`,
                  height: `50%`,
                  backgroundImage: `linear-gradient(-180deg,rgba(0,0,0,0) 2%,rgb(255, 255, 255) 100%) !important;`,
                },
                [mediaQueries.lg]: {
                  '::before': {
                    display: `none`,
                  },
                },
              })}
            />
          </div>
        )}
      </Container>
    </section>
  )
}