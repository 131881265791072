import React from "react";
import styled, { css } from "styled-components";
import { Link } from 'gatsby'
import posed from "react-pose";

import { media } from '../../utils/media'
import { zIndices } from '../../utils/tokens'
import BackgroundSlide from "./BackgroundSlide";

const staggerChildren = {
  open: {
    staggerChildren: 75,
    staggerDirection: 1
  },
  closed: {
    staggerChildren: 50,
    staggerDirection: -1
  }
};

const SidePanel = styled(posed.div(staggerChildren))`
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  position: absolute;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const animateItem = {
  open: {
    scale: 1,
    opacity: 1,
    transformOrigin: "100% 0%",
    transition: {
      duration: 600,
      delay: 200,
    },
    staggerDirection: 1
  },
  closed: {
    scale: 0.2,
    opacity: 0,
    transformOrigin: "100% 50%",
    transition: {
      duration: 400
    },
    staggerDirection: -1
  },
  hoverable: true,
  init: {
    x: "30px",
  },
  hover: {
    x: "0",
  }
};

const Item = styled(posed.div(animateItem))`
  margin-bottom: 1rem;
  text-align: right;

  ${media.desktop`
    margin-bottom: 1.25rem;
  `}
`;

const ItemLink = styled(Link)`
  color: ${props => props.theme.colors.white}a1;
  font-size: 1.8rem;
  cursor: pointer;
  font-weight: 600;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  text-decoration: none;
  text-transform: capitalize;
  transition: color 200ms;

  :hover {
    color: ${props => props.theme.colors.white};
  }

  ${media.desktop`
    font-size: 2.5rem;
  `}

  ${media.hd`
    font-size: 3rem;
  `}
`;

const Line = styled.hr `
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 25px;
  border-style: inset;
  border: 2px solid;
  box-sizing: border-box;
  width: 30px;
  color: ${props => props.theme.colors.primaryBrand};
`

const Span = styled.span `
`

const MenuList = styled.div`
  margin: none;
  list-style-type: none;
  width: 80%;
  overflow: hidden;

  ${media.desktop`
    width: 50%;
  `}
`;

const SideBarContainer = styled(
  posed.div({
    open: {
      staggerChildren: 400,
    },
    closed: {
      staggerChildren: 400,
    }
  })
)`
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: ${zIndices.sideBar};
  `;

export const SideBar = props => {
  const { isOpen } = props;
  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <SideBarContainer
        {...props}
        pose={isOpen ? 'open' : 'closed'}
        ref={props.hostRef}
      >
        <BackgroundSlide />
        <SidePanel {...props} key="sidePanel">
          <MenuList>
            <Item key={1}>
              <ItemLink to="/">
                <Span>Home</Span>
                <Line />
              </ItemLink>
            </Item>
            <Item key={2}>
              <ItemLink to="/services/">
                <Span>Services</Span>
                <Line />
              </ItemLink>
            </Item>
            <Item key={3}>
              <ItemLink to="/the-lab/">
                <Span>The Lab</Span>
                <Line />
              </ItemLink>
            </Item>
            <Item key={4}>
              <ItemLink to="/portfolio/">
                <Span>Porfolio</Span>
                <Line />
              </ItemLink>
            </Item>
            <Item key={5}>
              <ItemLink to="/gallery/">
                <Span>Gallery</Span>
                <Line />
              </ItemLink>
            </Item>
            <Item key={6}>
              <ItemLink to="/about/">
                <Span>About</Span>
                <Line />
              </ItemLink>
            </Item>
            <Item key={7}>
              <ItemLink to="/blog/">
                <Span>Blog</Span>
                <Line />
              </ItemLink>
            </Item>
            <Item key={8}>
              <ItemLink to="/contact/">
                <Span>Contact us</Span>
                <Line />
              </ItemLink>
            </Item>
            <Item key={9}>
              <ItemLink to="/get-a-quote/">
                <Span>Get a quote</Span>
                <Line />
              </ItemLink>
            </Item>
          </MenuList>
        </SidePanel>
      </SideBarContainer>
    </div>
  )
}