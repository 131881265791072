import React from "react";
import { PoseGroup } from 'react-pose'
import { css } from 'styled-components'

import  { BurgerMenu } from "./BurgerMenu";
import { SideBar } from "./SideBar";
import { mediaQueries } from "../../utils/tokens";

class DesktopMenu extends React.Component {
  state = {
    isOpen: false
  };

  handleClick = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    const { isOpen } = this.state;
    return (
      <div css={css`
        position: relative;
        display: block;
        ${mediaQueries.lg} {
          display: none;
        }
      `}>
        <BurgerMenu isOpen={isOpen} onClick={this.handleClick}/>
        <PoseGroup
          exitPose="closed"
          enterPose="open"
          preEnterPose="closed"
          flipMove={false}
        >
          {
            isOpen && 
              <SideBar
                isOpen={isOpen}
                key="sidebar"
              />
          }
        </PoseGroup>
      </div>
    );
  }
}

export default DesktopMenu