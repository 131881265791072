import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'styled-components'

import { space, fontSizes, mediaQueries } from '../utils/tokens'

import HeroHalfBackgroundCarousel from '../components/HeroHalfBackgroundCarousel'
import HeroTwoColumns from '../components/HeroTwoColumns'
import Container from './Container'
import { HeroDiagonal } from './HeroDiagonal'
import { HeroHalfImage } from './HeroHalfImage'
import { HeroTextOnly } from './HeroTextOnly'
import { HeroWithBlock } from './HeroWithBlock'

export const HeroSelector = ({
  hero,
  featuredCompanies,
  typeform,
  height
}) => {
  if(hero.__typename === `ContentfulHeroTextOnly`) {
    return (
      <HeroTextOnly hero={hero} />
    )
  } else if (hero.__typename === `ContentfulHeroWithBlock`) {
    return (
      <HeroWithBlock hero={hero} />
    )
  } else {
    if (hero.type === `half background carousel`) {
      return (
        <HeroHalfBackgroundCarousel
          hero={hero}
        />
      )
    } else if(hero.type === `HeroDiagonal`) {
      return (
        <HeroDiagonal
          hero={hero}
        />
      )

    } else if(hero.type === `two columns`) {
      return (
        <HeroTwoColumns
          hero={hero}
          typeform={typeform}
          featuredCompanies={featuredCompanies}
        />
      )

    } else if(hero.type === `HeroHalfImage`) {
      return (
        <HeroHalfImage
          hero={hero}
          featuredCompanies={featuredCompanies}
        />
      )
    } else {
      return (
        <section
          css={css({
            padding: `${space[5]}px ${space[4]}px`,
            [mediaQueries.lg]: {
              padding: `${space[6]}px ${space[4]}px ${space[5]}px`,
            },
          })}
        >
          <Container>
            <h1
              css={css({
                textTransform: `uppercase`,
                letterSpacing: `1.5px`,
                marginBottom: `${space[2]}px`,
                fontSize: fontSizes[4],
                fontWeight: `bold`,
              })}
            >
              {hero.title}
            </h1>
          </Container>
        </section>
      )
    }
  }
}

HeroSelector.propTypes = {
  hero: PropTypes.object.isRequired,
}