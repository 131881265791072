import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { css } from 'styled-components'

import {
  mediaQueries,
  space,
  breakpoints,
  fontSizes,
  colors,
} from '../utils/tokens'
import HeroButtons from './HeroButtons'

export const HeroHalfImage = props => {
  const { hero, featuredCompanies } = props

  const styles = {
    dark: {
      backgroundColor: colors.black,
      color: colors.white,
    },
    light: {

    },
    mute: {

    }
  }

  let heroStyle

  if(hero.theme) {
    heroStyle = styles["dark"] || {}
  }

  return (
    <section
      style={heroStyle}
      css={{
        padding: `${space[4]}px 0`,
        [mediaQueries.lg]: {
          padding: `${space[5]}px 0`,
        },
        [mediaQueries.xxl]: {
          padding: `${space[6]}px 0`,
        }
      }}
    >
      <div
        css={css({
          maxWidth: breakpoints.xxxl,
          margin: `auto`,
          display: `flex`,
          flexDirection: `column-reverse`,
          [mediaQueries.lg]: {
            display: `grid`,
            gridTemplateColumns: `1fr 1fr`,
            gridGap: `${space[4]}px`,
            padding: 0,
          },
        })}
      >
        <div
          css={css({
            padding: `${space[4]}px`,
            display: `flex`,
            flexDirection: `column`,
            [mediaQueries.lg]: {
              alignSelf: `center`,
              maxWidth: `${breakpoints.sm}`,
              padding: `${space[5]}px`,
              marginLeft: `auto`,
            },
            [mediaQueries.xxl]: {
              padding: `${space[5]}px 0`,
            }
          })}
        >
          <h1
            css={css({
              letterSpacing: `1.5px`,
              marginBottom: `${space[2]}px`,
              color: `inherit`,
              [mediaQueries.lg]: {
                marginBottom: `${space[3]}px`,
              }
            })}
          >
            {hero.subtitle && (
              <span
                css={css({
                  fontSize: fontSizes[2],
                  display: `block`,
                  color: colors.primaryBrand,
                  [mediaQueries.lg]: {
                    fontSize: fontSizes[2],
                  },
                })}
              >
                {hero.subtitle.internal.content}&nbsp;
              </span>
            )}
            <span
              css={css({
                fontSize: fontSizes[4],
                fontWeight: `bold`,
                [mediaQueries.lg]: {
                  fontSize: fontSizes[5],
                },
              })}
            >
              {hero.title}
            </span>
          </h1>
          {hero.tagline && (
            <div
              dangerouslySetInnerHTML={{
                __html: hero.tagline.childMarkdownRemark.html,
              }}
              css={css({
                fontSize: fontSizes[2],
                lineHeight: 1.2,
                marginTop: `${space[0]}px`,
                marginBottom: `${space[4]}px`,
              })}
            />
          )}
          <div
            css={css({
              display: `flex`,
              flexDirection: `column`,
            })}
          >
            <HeroButtons
              callToActionText={hero.callToActionText}
              callToActionUrl={hero.callToActionUrl}
              callToActionUrlSecondary={hero.callToActionUrlSecondary}
              callToActionTextSecondary={hero.callToActionTextSecondary}
            />
          </div>
          {featuredCompanies && (
            <div
              css={css({
                marginTop: `${space[5]}px`,
              })}
            >
              <div
                css={css({
                  display: `grid`,
                  gridGap: `${space[4]}px`,
                  gridTemplateColumns: `repeat(3, 80px)`,
                  justifyContent: `center`,
                  alignItems: `center`,
                })}
              >
                {featuredCompanies.map((company, id) => {
                  return (
                    <div key={id}>
                      <img
                        src={company.file.url}
                        alt={company.title}
                        css={css({
                          filter: `grayscale(1)`,
                          opacity: 0.75,
                          width: `auto`,
                          margin: `auto`,
                        })}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
        {hero.backgroundPhoto && (
          <div
            css={css({
              marginBottom: `${space[4]}px`,
              [mediaQueries.lg]: {
                marginBottom: 0,
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `center`,
              },
            })}
          >
            <Img
              fluid={hero.backgroundPhoto.fluid}
              alt={hero.backgroundPhoto.title}
              loading="eager"
              fadeIn={false}
            />
          </div>
        )}
      </div>
    </section>
  )
}

HeroHalfImage.propTypes = {
  hero: PropTypes.object,
  featuredCompanies: PropTypes.arrayOf(PropTypes.object),
  typeform: PropTypes.string,
}
