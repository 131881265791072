import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
} from 'react-icons/fa'

import { space, palette } from '../utils/tokens'
import { media } from '../utils/media'

const UnstyledAnchor = styled.a`
  &&& {
    text-decoration: none;
    color: ${props => props.theme.colors.white};
    font-size: 1.25rem;
    font-weight: 500;
    z-index: 1;
    margin-right: 0.5rem;

    transition-duration: 150ms;
    
    :hover {
      opacity: 1;
    }
  }
`

const Wrapper = styled.footer`
  background-color: ${props => props.theme.colors.base};
  width: 100%;
  min-height: 300px;
  padding: 4rem 2rem;

  ${media.desktop`
    padding: 6rem 0 2rem;
  `}
`

const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0;
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: auto;
  list-style: none;

  ${media.desktop`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;
  `}
`

const Item = styled.li`
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
  a {
    font-weight: 600;
    transition: all 0.2s;
    color: ${props => props.theme.colors.base};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
    &:visited {
      color: ${props => props.theme.colors.base};
    }
  }
`

const ItemLink = styled(Link)`
  &&& {
    display: inline-block;
    color: ${palette.grey[30]};
    font-weight: 500;
    text-decoration: none;
    padding-bottom: 0.5rem;
  }
`

const AttributionText = styled.p`
  color: ${props => props.theme.colors.white};
  font-size: 14px;
  margin-bottom: 0.5rem;
`
const CenterBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.base};
  width: 100%;
`

const ItemTitle = styled.h5`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  color: ${props => props.theme.colors.white}c4;
  letter-spacing: 4px;
  display: block;
  text-transform: uppercase;
  margin-bottom: 2rem;

  ${media.desktop`
    margin-bottom: 2rem;
  `}
`
const Footer = () => (
  <Wrapper>
    <List>
      <Item
        css={css`
          grid-column: 1;
          margin-bottom: 2rem;

          ${media.desktop`
            margin-bottom: 0;
            grid-column: 2;
          `}
        `}
      >
        <ItemTitle>Offices</ItemTitle>
        <List
          css={css`
            &&& {
              padding: 0;
              display: flex;
              flex-direction: column;
              margin-top: 0;
            }
          `}
        >
          <Item
            css={css`
              margin-bottom: 1rem;
              ${media.desktop`
            margin-top: 0;
          `}
            `}
          >
            <ItemLink
              to="/locations/new-york/queens/"
            >
              Queens
            </ItemLink>
          </Item>
          <Item>
            <ItemLink to="/locations/new-york/brooklyn/">Brooklyn</ItemLink>
          </Item>
        </List>
      </Item>
      <Item
        css={css`
          display: flex;
          flex-direction: column;
          grid-column: 1;
          grid-row: 1;
          margin-bottom: 2rem;

          ${media.desktop`
          grid-column: 1;
        `}
        `}
      >
        <ItemTitle>Links</ItemTitle>
        <List
          css={css`
            &&& {
              padding: 0;
              display: flex;
              flex-direction: column;
              margin-top: 0;
            }
          `}
        >
          <Item>
            <ItemLink
              to="/file-upload/"
            >
              File upload
            </ItemLink>
          </Item>
          <Item
            css={css`
              margin-bottom: 1rem;
            `}
          >
            <ItemLink
              to="/sales-policy/"
            >
              Sales Policy
            </ItemLink>
          </Item>
          <Item>
            <ItemLink
              to="/confidential-policy/"
            >
              Confidential Policy
            </ItemLink>
          </Item>
          <Item>
            <ItemLink
              to="/campaigns/"
            >
              Campaigns
            </ItemLink>
          </Item>
        </List>
      </Item>
      <Item>
        <ItemTitle>Resources</ItemTitle>
        <List
          css={css`
            &&& {
              padding: 0;
              display: flex;
              flex-direction: column;
              margin-top: 0;
            }
          `}
        >
          <Item>
            <ItemLink to="/blog/">Blog</ItemLink>
          </Item>
          <Item>
            <ItemLink to="/guides/">Guides</ItemLink>
          </Item>
        </List>
      </Item>
      <Item
        css={css`
          display: flex;
          flex-direction: column;
          grid-column: 2;
          grid-row: 1;
          margin-bottom: 2rem;

          ${media.desktop`
            grid-column: 4;
          `}
        `}
      >
        <ItemTitle>Social</ItemTitle>
        <div>
          <UnstyledAnchor
            href="https://instagram.com/printaworld3d"
            target="_blank"
            rel="noopener noreferrer nofollow"
            aria-label="printaworld's instagram page"
          >
            <FaInstagram />
          </UnstyledAnchor>
          <UnstyledAnchor
            href="https://www.facebook.com/printaworld"
            target="_blank"
            rel="noopener noreferrer nofollow"
            aria-label="printaworld's instagram page"
          >
            <FaFacebook />
          </UnstyledAnchor>
          <UnstyledAnchor
            href="https://www.twitter.com/PrintAworld3D"
            target="_blank"
            rel="noopener noreferrer nofollow"
            aria-label="printaworld's twitter page"
          >
            <FaTwitter />
          </UnstyledAnchor>
          <UnstyledAnchor
            href="https://www.linkedin.com/company/printaworld"
            target="_blank"
            rel="noopener noreferrer nofollow"
            aria-label="printaworld's linkedin page"
          >
            <FaLinkedin />
          </UnstyledAnchor>
        </div>
      </Item>
    </List>
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: ${space[4]}px 0;
      `}
    >
      <AttributionText>Designed with ❤️ by Ian Vince</AttributionText>
      <a
        href="https://www.contentful.com/"
        rel="nofollow noopener noreferrer"
        target="_blank"
      >
        <img
          src="https://images.ctfassets.net/fo9twyrwpveg/7F5pMEOhJ6Y2WukCa2cYws/398e290725ef2d3b3f0f5a73ae8401d6/PoweredByContentful_DarkBackground.svg"
          style={{ maxWidth: `100px`, width: `100%` }}
          alt="Powered by Contentful"
        />
      </a>
    </div>
    <CenterBottom>
      <p
        css={css`
          color: ${props => props.theme.colors.white};
          ${media.desktop`
          margin-top: 2rem;
        `}
        `}
      >
        © Copyright 2019 Big Tech
      </p>
    </CenterBottom>
  </Wrapper>
)

export default Footer
